import { inject } from '../../../../../../../common/container/inject';
import { injectEntityList } from '../../../../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../../../../common/store/base/injectPrimitive';
import { IEntityListStore } from '../../../../../../../common/store/interface/IEntityListStore';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IRequirementService } from '../../../../../../../service/requirement/entity/IRequirementService';
import { RequirementTemplateType, RequirementType } from '../../../../../../../service/requirement/entity/RequirementType';
import { IVendorRequirementModel } from '../../../../../../../service/requirement/vendor/IVendorRequirementModel';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { IVendorEntityModel } from '../../../../../../../service/vendor/entity/IVendorEntityModel';
import { AutocompleteSelectItem } from '../../../../../../design/form/FormAutocomplete';
import { LayoutNotificationType } from '../../../../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { FormDomain } from '../../../../form/store/FormDomain';
import { RequirementFormModel } from './RequirementFormModel';
import { RequirementFormUI } from './RequirementFormUI';

export class RequirementFormDomain extends FormDomain<RequirementFormModel, IRequirementService> {
  private onSave: any = null;
  private onCancel: any = null;
  private onDelete: any = null;
  private isDelete: boolean = false;
  public loacalRequirementType = injectPrimitive<string>(RequirementTemplateType.custom);
  public requirements: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([]);
  public vendorRequirements: IEntityListStore<IVendorRequirementModel> = injectEntityList<IVendorRequirementModel>([])
  public requirementByCategory: IEntityListStore<IVendorRequirementModel> = injectEntityList<IVendorRequirementModel>([])
  public vendorCategories: IEntityListStore<IVendorEntityModel> = injectEntityList<IVendorEntityModel>([])
  public templateRequirementList = injectPrimitive<AutocompleteSelectItem[]>([])
  public selectedRequirement = injectPrimitive<string>('')
  public selectedRequirementName = injectPrimitive<string | undefined>('')
  public selectedVendorCategory = injectPrimitive<string>('')
  public ui: RequirementFormUI;

  constructor(
    layoutDomain: IMainLayoutDomainStore,
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
  ) {
    super(layoutDomain, rootService.requirement.entity);
    this.ui = new RequirementFormUI(this)
  }

  loadData = async (id: string | null, applicationId?: any) => {
    await super.loadData(id);
    if (applicationId) {
      await this.loadRequirements()

      this.loacalRequirementType.setValue(this.ui.model.entity.templateType || RequirementTemplateType.custom)
      this.ui.applicationId.setValue(applicationId);
      this.ui.type.setValue(RequirementType.local);
      if (this.loacalRequirementType.value === RequirementTemplateType.fromRequirement) {
        const currentRequirements = this.requirements.list
          .filter(item => item.categoryId === this.ui.model.entity.categoryId)
          .map(item => ({ name: item.shortName || '', value: item.id, categoryId: item.categoryId, description: item?.description }))
        this.templateRequirementList.setValue(currentRequirements)
      }
    } else {
      this.ui.type.setValue(RequirementType.system);
    }

    await this.loadDependencies();
  };

  //@todo Promise.all
  loadDependencies = async () => {
    try {
      this.ui.isLoading.setValue(true);
      await this.loadRequirements()
      const defaultSearchQuery = { limit: 1000000 };
      const tagsSearch = await this.rootService.tag.entity.search(defaultSearchQuery);

      const tagsCategoriesSearch = await this.rootService.tag.category.search(defaultSearchQuery);
      const specificationsSearch = await this.rootService.specification.entity.search(defaultSearchQuery);
      const specificationsCategoriesSearch = await this.rootService.specification.category.search(defaultSearchQuery);
      const performerTypesSearch = await this.rootService.performer.type.search(defaultSearchQuery);
      const categories = await this.rootService.requirement.category.search(defaultSearchQuery);

      this.ui.tags.setList(tagsSearch.data);
      this.ui.tagsCategories.setList(tagsCategoriesSearch.data);
      this.ui.specifications.setList(specificationsSearch.data);
      this.ui.performersType.setList(performerTypesSearch.data);
      this.ui.categories.setList(categories.data);
      this.ui.specificationsCategories.setList(specificationsCategoriesSearch.data);

      this.ui.isLoading.setValue(false);
    } catch (error) {
      return this.errorsHandler(error);
    }
  };

  setShortNameGeneratorState(isCanGenerate: boolean) {
    this.ui.isCanGenerateShortName.setValue(isCanGenerate);
  }

  generateShortName = async (): Promise<string> => {
    const categoryId = this.ui.model.entity.categoryId || null;
    if (categoryId && this.ui.isCanGenerateShortName.value === true) {
      const categoryRequirements = await this.rootService.requirement.entity.search({
        filter: { categoryId: { equal: categoryId }, type: { equal: RequirementType.system } },
      });
      const namesNumbers = categoryRequirements.data.map((requirement) =>
        Math.round(Number(requirement.shortName?.replace(/^\D+/g, '') || 0)),
      );

      namesNumbers.push(0);
      const lastNameIndex = Math.max(...namesNumbers.filter((item) => `${item}` !== `NaN`));
      const newNameIndex = lastNameIndex + 1;
      const categoryName = this.ui.categories.list.find((category) => category.id === categoryId)?.shortName || '';
      const generatedShortName = `${categoryName}${newNameIndex}`;
      this.ui.model.entity.shortName = generatedShortName;
    }

    return '';
  };

  setEvents(onDelete: any, onSave: any, onCancel: any) {
    this.onDelete = onDelete || this.onDelete;
    this.onSave = onSave || this.onSave;
    this.onCancel = onCancel || this.onCancel;
  }

  setIsDelete(isDelete: any) {
    this.isDelete = isDelete === true;
  }

  save = async () => {
    this.ui.isLoading.setValue(true)
    const type = this.ui.type.value;
    if (type === RequirementType.local) {
      await this.saveLocal(this.ui.applicationId.value);
    }
    if (type === RequirementType.system) {
      await this.saveSystem();
    }
    this.ui.isLoading.setValue(false)
  };

  saveLocal = async (applicationId: string) => {
    await this.removeValidationErrors();
    const isHaveSameShortName = await this.validateShortName()

    const entity = {
      ...this.ui.model.entity,
      applicationId,
    };
    if (isHaveSameShortName && !entity.id) {
      await this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.error,
        text: `Требование с таким названием уже существет в системе.`,
      });
      return
    }

    let serviceCallHandler: any = null;

    serviceCallHandler = entity.id
      ? this.rootService.requirement.entity.updateLocal(entity)
      : this.entityService.createLocal(entity);

    await this.callService(serviceCallHandler);
    if (this.ui.validationErrors.list.length === 0) {
      if (this.onSave) {
        this.onSave();
      } else {
        this.router.goTo(`/application/${applicationId}/info`);
      }
    }
    this.ui.isLoading.setValue(false)
  };

  saveSystem = async () => {
    await this.removeValidationErrors();
    const entity = this.ui.model.entity;
    let serviceCallHandler: any = entity.id
      ? this.rootService.requirement.entity.updateByModel(entity)
      : this.entityService.createByModel(entity);

    await this.callService(serviceCallHandler);
    if (this.ui.validationErrors.list.length === 0) {
      if (this.onSave) {
        this.onSave();
      } else {
        this.router.goTo('/settings/requirement/entity');
      }
    }
    this.ui.isLoading.setValue(false)
  };

  delete = async () => {
    if (this.isDelete) {
      const type = this.ui.type.value;
      if (type === RequirementType.local) {
        await this.rootService.requirement.entity.deleteLocal(this.ui.model.entity.id || '');
      }
      if (type === RequirementType.system) {
        await this.rootService.requirement.entity.deleteById(this.ui.model.entity.id || '');
      }
      if (this.onDelete) {
        this.onDelete();
      }
    }
  };

  cancelEdit = async () => {
    if (this.onCancel) {
      this.onCancel();
    } else {
      const type = this.ui.type.value;
      if (type === RequirementType.local) {
        this.router.goTo(`/application/${this.ui.applicationId.value}/info`);
      }
      if (type === RequirementType.system) {
        this.router.goTo('/settings/requirement/entity');
      }
    }
  };

  async loadRequirements() {
    const requirements = (await this.rootService.requirement.entity.search({
      limit: 10000, fields: [
        'shortName',
        'description',
        'descriptionWithStyles',
        'categoryId',
        'templateType'
      ],
      filter: { type: { equal: RequirementType.system } },
    })).data
    this.requirements.setList(requirements)

    const vendorRequirements = (await this.rootService.requirement.vendor.search({
      limit: 10000, fields: [
        'shortName',
        'description',
        'descriptionWithStyles',
        'vendorId'
      ],
    })).data
    this.vendorRequirements.setList(vendorRequirements)

    const vendorCategories = (await this.rootService.vendor.entity.search({
      limit: 10000, fields: ['name', 'id']
    })).data
    this.vendorCategories.setList(vendorCategories)
  }

  async changeLocalRequirementType(event) {
    this.loacalRequirementType.setValue(event.target.value)
    this.clearForm()

    if (event.target.value === RequirementTemplateType.fromRequirement) {
      const newRequirementsList = this.requirements.list.map(item => ({ name: item.shortName || '', value: item.id, categoryId: item.categoryId }))
      this.templateRequirementList.setValue(newRequirementsList)
    } if (event.target.value === RequirementTemplateType.fromVendor) {
      this.templateRequirementList.setValue(this.vendorRequirements.list.map(item => ({ name: item.shortName || '', value: item.id })))
    }
    const uiEntity = this.ui.model.entity
    uiEntity.templateType = event.target.value
  }

  async clearForm() {
    this.ui.model.setEntity({
      ...this.ui.model.entity,
      categoryId: undefined,
      description: '',
      descriptionWithStyles: '',
      name: '',
      shortName: '',
      orderIndex: undefined,
      id: this.ui.model.entity.id || undefined
    })
    this.selectedRequirement.setValue('')
    this.selectedVendorCategory.setValue('')
  }

  async changeTemplateRequirement(newValue) {
    this.selectedRequirement.setValue(newValue.value)
    const uiEntity = this.ui.model.entity
    if (this.loacalRequirementType.value === RequirementTemplateType.fromRequirement) {
      const currentRequiremnt = this.requirements.list.find(req => {
        return req.id === newValue.value
      })
      this.selectedRequirementName.setValue(currentRequiremnt?.shortName)
      const clearDescription = currentRequiremnt?.description ? currentRequiremnt?.description.replaceAll('&nbsp;', ' ') : undefined

      uiEntity.categoryId = currentRequiremnt?.categoryId
      uiEntity.description = clearDescription
      uiEntity.descriptionWithStyles = currentRequiremnt?.descriptionWithStyles
      uiEntity.shortName = currentRequiremnt?.shortName
      this.ui.model.setEntity(uiEntity)
    } if (this.loacalRequirementType.value === RequirementTemplateType.fromVendor) {
      const currentRequiremnt = this.vendorRequirements.list.find(req => {
        return req.id === newValue.value
      })
      const clearDescription = currentRequiremnt?.description.replaceAll('&nbsp;', ' ')
      this.selectedRequirementName.setValue(currentRequiremnt?.shortName)
      uiEntity.description = clearDescription
      uiEntity.descriptionWithStyles = currentRequiremnt?.descriptionWithStyles
      uiEntity.shortName = currentRequiremnt?.shortName
      this.ui.model.setEntity(uiEntity)
    }
  }

  async validateShortName() {
    const application = await this.rootService.application.entity.getById(this.ui.applicationId.value || '')
    const applicationData = await this.rootService.application.data.getById(application.dataId || '')
    const requirementsIds: string[] = []
    if (applicationData.savedRequirementsIds) {
      requirementsIds.push(...applicationData.savedRequirementsIds)
    }
    if (applicationData.newestRequirementsIds) {
      requirementsIds.push(...applicationData.newestRequirementsIds)
    }
    if (applicationData.deletedRequirementsIds) {
      requirementsIds.push(...applicationData.deletedRequirementsIds)
    }

    if (!requirementsIds.length) {
      return false
    }

    const requirements = await Promise.all(requirementsIds.map(async (reqId) => {
      return await this.rootService.requirement.entity.getById(reqId)
    }))

    const isHaveSameShortName = !!requirements.find(item => {
      return item.shortName === this.ui.model.entity.shortName
    })

    return isHaveSameShortName
  }
}
