import React from 'react';

import { inject } from '../../../../../../../common/container/inject';
import { IError } from '../../../../../../../common/error/IError';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { ConfirmModal } from '../../../../../../../view/design/modal/confirm/ConfirmModal';
import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../service/LearningRootService';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { IUnitListAdminPageDomain } from './IUnitListAdminPageDomain';
import { IUnitListAdminPageUI } from './IUnitListAdminPageUI';
import { UnitListAdminPageUI } from './UnitListAdminPageUI';

export class UnitListAdminPageDomain implements IUnitListAdminPageDomain {
  public ui: IUnitListAdminPageUI;

  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    private rootService = injectRootService(layoutDomain.serviceType.value),
  ) {
    this.ui = new UnitListAdminPageUI();
  }
  publishUnit = async (id: string) => {
    const unitData = this.ui.unitList.list.filter((unit) => unit.id === id)[0];
    unitData.theory.chapters = unitData.theory.chapters.map((chapter) => {
      return {
        ...chapter,
        theoryBlocks: chapter.theoryBlocks.map((theoryBlock) => {
          const { Element, ...rest } = theoryBlock;
          return rest;
        }),
      };
    });

    await this.learningRootService.unit
      .publishByModel({ ...unitData, isPublished: true })
      .catch(this.dataLoadErrorCatch);

    this.ui.unitList.list.forEach((unit) => {
      if (unit.id === id) {
        unit.isPublished = true;
      }
    });
  };

  unpublishUnit = async (id: string) => {
    const unitData = this.ui.unitList.list.filter((unit) => unit.id === id)[0];
    unitData.theory.chapters = unitData.theory.chapters.map((chapter) => {
      return {
        ...chapter,
        theoryBlocks: chapter.theoryBlocks.map((theoryBlock) => {
          const { Element, ...rest } = theoryBlock;
          return rest;
        }),
      };
    });

    await this.learningRootService.unit
      .unpublishByModel({ ...unitData, isPublished: false })
      .catch(this.dataLoadErrorCatch);

    this.ui.unitList.list.forEach((unit) => {
      if (unit.id === id) {
        unit.isPublished = false;
      }
    });
  };

  loadData = async () => {
    this.ui.isLoading.setValue(true);
    const searchResult = await this.learningRootService.unit
      .search({
        limit: 1000000,
      })
      .catch((err) => {
        if (err.webCode === '500') {
          return this.router.goTo('/serviceNotAvailable');
        }
      });

    this.ui.unitList.setList(searchResult.data);
    const { data: specificationCategoryData } = await this.rootService.specification.category.search({
      fields: ['name', 'id'],
      limit: 100000,
    });
    this.ui.specificationCategory.setList(specificationCategoryData);
    const { data: characteristicData } = await this.rootService.specification.entity.search({
      limit: 10000,
      fields: ['name', 'categoryId'],
    });

    if (characteristicData) {
      setTimeout(() => {
        this.ui.isLoading.setValue(false);
      }, 300);
    }
    this.ui.characteristic.setList(characteristicData);
    this.createdGroupedList();
    this.ui.filtredGroupedUnits.setList(this.ui.groupedUnits.list);
  };

  createdGroupedList = () => {
    const charIdToSpecNameMap = this.ui.characteristic.list.reduce((map, char) => {
      const spec = this.ui.specificationCategory.list.find((spec) => spec.id === char.categoryId);
      if (spec) {
        map[char.id] = spec.name;
      }
      return map;
    }, {});

    const groups = { 'Без Контекста': [] };

    this.ui.unitList.list.forEach((unit) => {
      const charIds = unit.settings.characteristicsIds;
      if (charIds && charIds.length > 0) {
        const charId = charIds[0];
        const groupName = charIdToSpecNameMap[charId] || charId;
        groups[groupName] = groups[groupName] || [];
        groups[groupName].push(unit);
      } else {
        groups['Без Контекста'].push(unit);
      }
    });

    const sortedGroupList = Object.keys(groups)
      .map((groupName) => ({
        groupName,
        unitsList: groups[groupName],
      }))
      .sort((a, b) => (a.groupName || '').localeCompare(b.groupName || ''));
    this.ui.groupedUnits.setList(sortedGroupList);
  };

  createUnit = async () => {
    return await this.learningRootService.unit.createByModel(this.ui.unit.entity);
  };

  onEditUnit = async (unitId: string) => {
    this.router.goTo(`/learning/admin/edit/${unitId}`);
    await this.loadData();
  };

  deleteUnit = (unitId: string) => {
    const Component = () => {
      return (
        <ConfirmModal
          title="Удалить юнит"
          text={`Вы уверены, что хотите удалить юнит ?`}
          onCancel={async () => {
            this.layoutDomain.modalWindow.closeModalWindow();
          }}
          onOk={async () => {
            await this.learningRootService.unit.deleteById(unitId);
            this.ui.groupedUnits.setList([{ groupName: 'Без Контекста', unitsList: [] }]);
            await this.loadData();
            this.layoutDomain.modalWindow.closeModalWindow();
          }}
        />
      );
    };
    return Component;
  };

  onDeleteUnit = (unitId: string) => {
    this.layoutDomain.modalWindow.showModalWindow(this.deleteUnit(unitId));
  };

  redirectToCreateUnit = () => {
    this.createUnit().then((unitId) => {
      this.router.goTo(`/learning/admin/create/${unitId}`);
    });
  };

  handleSearchByGroupsAndName = (searchText: string) => {
    const trimmedSearchText = searchText.trim();

    const filteredGroups = this.ui.groupedUnits.list
      .map((group) => {
        const isGroupNameMatch = group.groupName.toLowerCase().includes(trimmedSearchText.toLowerCase());
        const filteredUnits = group.unitsList.filter((unit) =>
          unit.settings.titleHeading.toLowerCase().includes(trimmedSearchText.toLowerCase()),
        );

        return {
          ...group,
          unitsList: isGroupNameMatch ? group.unitsList : filteredUnits,
        };
      })
      .filter((group) => group.unitsList.length > 0);

    this.ui.filtredGroupedUnits.setList(filteredGroups);
  };

  handleFilterByIsPublished = (filter: string) => {
    this.ui.filterByIsPublished.setValue(filter);
  };

  handleFiterByIsPublished
  private dataLoadErrorCatch = (error: IError) => {
    this.layoutDomain.errors.handleError(error);
    return {
      data: [],
    };
  };

  updateUnitsProjectRoles = async (roleId: string, unitIds: string[]) => {
    this.learningRootService.unit
      .updateProjectRoles({
        roleId,
        unitIds,
      })
      .catch((err) => {
        console.warn(err);
      });
  };
}
