import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../../service/unit/interface/IUnitModel';

export const LANGUAGES_MAP = {
  javascript: { title: 'JavaScript', color: '#ECF0F1' },
  html: { title: 'HTML', color: '#FFDAB9' },
  php: { title: 'PHP', color: '#98FBBA' },
  java: { title: 'JAVA', color: '#FF69B4' },
  csharp: { title: 'C#', color: '#FF9393' },
  cpp: { title: 'C++', color: '#98FB98' },
  xml: { title: 'XML', color: '#FFDD84' },
  python: { title: 'Python', color: '#FFE135' },
  yaml: { title: 'YAML', color: '#FFC0CB' },
  plaintext: { title: 'Plain Text', color: '#87CEEB' },
  dockerfile: { title: 'Docker', color: '#FFFACD' },
  bat: { title: 'Bash', color: '#FFD700' },
  css: { title: 'CSS', color: '#DEB887' },
  mysql: { title: 'SQL', color: '#F5FFFA' },
  go: { title: 'GO', color: '#00FFFF' },
  json: { title: 'JSON', color: '#FFC107' },
};

type Props = {
  unit?: IUnitModel;
};

export const UnitCardLangs = observer(({ unit }: Props) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 4, marginTop: 4 }}>
      {unit?.practice.tasks?.[0].languages.map((item) => (
        <Box
          sx={{
            borderColor: LANGUAGES_MAP[item.name].color,
            color: LANGUAGES_MAP[item.name].color,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '16px',
            padding: '4px 8px',
            fontSize: '12px'
          }}
        >
          {LANGUAGES_MAP[item.name].title}
        </Box>
      ))}
    </Box>
  );
});
