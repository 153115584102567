import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../../service/unit/interface/IUnitModel';
import { OverflownContent } from '../../../LearningUnitPreviewPage/shared/OverflownContent';

const STATUS_MAP = {
  important: {
    title: 'Обязательные',
    borderColor: '#90CAF9',
    color: '#90CAF9',
    icon: <InfoOutlinedIcon sx={{ color: '#90CAF9' }} />,
  },
  unimportant: { title: 'Необязательные', borderColor: '#FFFFFF50', color: '#FFF', icon: undefined },
};

type Props = {
  unit?: IUnitModel;
  status: 'important' | 'unimportant';
};

export const UnitCardHeader = observer(({ unit, status }: Props) => {
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', gap: 1 }}>
      <OverflownContent contentClasses={classes.groupTitle}>{unit?.settings.titleHeading}</OverflownContent>
      <Box
        sx={{
          borderColor: STATUS_MAP[status].borderColor,
          color: STATUS_MAP[status].color,
          borderWidth: '1px',
          borderStyle: 'solid',
          height: 'fit-content',
          borderRadius: '16px',
          padding: '4px 8px',
          display: 'flex',
          gap: '4px',
        }}
      >
        {STATUS_MAP[status].icon}
        {STATUS_MAP[status].title}
      </Box>
    </Box>
  );
});

const useStyles = makeStyles(() => ({
  groupTitle: {
    fontWeight: '600',
    fontSize: '20px',
    cursor: 'pointer',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    lineClamp: 2,
  },
}));
