import React from 'react';

import { inject } from '../../../../../../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../../../../../../service/route/IRouterService';
import { LayoutNotificationType } from '../../../../../../../../../view/layout/common/notification/store/ILayoutNotification';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../../../service/LearningRootService';
import { ITask } from '../../../../../../../service/unit/interface/IUnitPractice';
import { ITasksQuizResult } from '../../../../../../../service/unitResult/IUnitResultModel';
import { IFile } from '../../../../../../design/codeEditor/parts/fileTree/IFileTree';
import { PassingCodeEditorDomain } from '../../../../../../design/codeEditor/passingStore/PassingCodeEditorDomain';
import { PassingPracticeStart } from '../PassingPracticeStart';
import { PassingTask } from '../PassingTask';
import { IPassingPracticeDomain } from './IPassingPracticeDomain';
import { PassingPracticeUI } from './PassingPracticeUI';

export class PassingPracticeDomain implements IPassingPracticeDomain {
  constructor(
    public ui = new PassingPracticeUI(),
    public passingCodeEditorDomain = new PassingCodeEditorDomain(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
  ) {}

  boot = async () => {
    this.ui.isExpanded.setValue(true);
    this.ui.stepperList.setList(this.ui.passingTaskList.list);
    await this.getCurrentEntities();
    this.preCreateTaskList();
    const titles = this.ui.passingTaskList.list.map((task) => `Задача ${task.sortIndex}`);
    this.ui.stepsTitles.setList(['Выбор языка', ...titles]);
    const languages = this.ui.passingTaskList.list[0].languages.map((lang) => {
      return lang.name;
    });
    this.ui.stepContent.setList([
      {
        component: (
          <PassingPracticeStart languages={languages} pickingLang={this.pickingLang} pickedLang={this.ui.pickedLang} />
        ),
        isEnable: true,
      },
    ]);
    const navigationList = this.ui.passingTaskList.list.map((task: ITask, index) => {
      return {
        title: `Задача ${task.sortIndex}`,
        passed: false,
        isActive: index === 0 ? true : false,
        navigationRedirect: this.navigationRedirect,
      };
    });
    if (this.ui.pickedLang.value !== '') {
      this.pickingLang(this.ui.pickedLang.value);
    }
    this.ui.navigationList.setList(navigationList);
  };

  totalSteps = () => {
    return this.ui.stepsTitles.list.length;
  };

  completedSteps = () => {
    return Object.keys(this.ui.completed.value).length;
  };

  isLastStep = () => {
    return this.ui.activeStep.value === this.totalSteps() - 1;
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps();
  };
  redirect = (page: string) => {};
  redirectInNavigation = (page: string) => {};

  handleNext = async () => {
    if (this.ui.activeStep.value === 0 && this.ui.pickedLang.value === '') {
      this.ui.layoutDomain.entity.notifications.showNotification({
        type: LayoutNotificationType.info,
        text: 'Выберите язык',
      });
    } else {
      const newActiveStep =
        this.isLastStep() && !this.allStepsCompleted()
          ? this.ui.stepsTitles.list.findIndex((step, i) => !(i in this.ui.completed.value))
          : this.ui.activeStep.value + 1;

      this.ui.activeStep.setValue(newActiveStep);
      this.ui.navigationList.list.forEach((chapter, index) => {
        if (index + 1 === newActiveStep) {
          chapter.isActive = true;
        }

        if (index < newActiveStep - 1) {
          this.ui.navigationList.list[index].passed = true;
        }
      });
    }
  };
  handleBack = () => {
    this.ui.activeStep.setValue(this.ui.activeStep.value - 1);
    if (this.ui.activeStep.value === 0) {
      this.ui.pickedLang.setValue('');
    }
  };
  resetActiveStep = () => {
    this.ui.activeStep.setValue(-1);
  };

  getCurrentEntities = async () => {
    const currentUnitResultSearch = await this.learningRootService.unitResult.search({
      filter: [
        { fieldName: 'userId', type: 'equal', value: this.ui.layoutDomain.entity.ui.activeUser.entity.id },
        { fieldName: 'unitId', type: 'equal', value: this.ui.passingId.value },
      ],
    });
    const currentUnitSearch = await this.learningRootService.unit.getById(this.ui.passingId.value);
    this.ui.currentUnit.setValue(currentUnitSearch);
    this.ui.currentUnitResult.setValue(currentUnitResultSearch.data[0]);
  };

  computeTotalResult = () => {
    let numberOfCorrectOptions = 0;
    for (let i = 0; i < this.ui.answersList.list.length; i++) {
      numberOfCorrectOptions += this.ui.answersList.list[i].result;
    }
    const totalResult = numberOfCorrectOptions * 25;
    return totalResult;
  };

  checkPassTask = async (task: ITask) => {
    this.ui.answersList.list.forEach((answer) => {
      if (answer.task === task.pseudoId) {
        answer.lastPassingDate = new Date();
      }
    });

    await this.learningRootService.unitResult.passPractice({
      unitResultId: this.ui.currentUnitResult.value.id as string,
      tasks: this.ui.answersList.list,
      pickedLang: this.ui.pickedLang.value,
    });

    const newUnitResults = await this.learningRootService.unitResult.search({
      filter: [
        { fieldName: 'userId', type: 'equal', value: this.ui.layoutDomain.entity.ui.activeUser.entity.id },
        { fieldName: 'unitId', type: 'equal', value: this.ui.passingId.value },
      ],
    });

    this.ui.answersList.setList(newUnitResults.data[0].practiceResult.tasks as any);
  };

  canCheckTask = (answer: ITasksQuizResult) => {
    const lastPassingDate = answer.lastPassingDate;
    if (!lastPassingDate) {
      return true;
    }
    const currentTime = new Date();
    const lastPassingTime = new Date(lastPassingDate);
    const diffInMinutes = (currentTime.getTime() - lastPassingTime.getTime()) / 1000 / 60;
    this.ui.timeIsLeft.setValue(diffInMinutes);
    return diffInMinutes >= 20;
  };

  finishPassing = async () => {
    const currentUnitResultSearch = await this.learningRootService.unitResult.search({
      filter: [
        { fieldName: 'userId', type: 'equal', value: this.ui.layoutDomain.entity.ui.activeUser.entity.id },
        { fieldName: 'unitId', type: 'equal', value: this.ui.passingId.value },
      ],
    });
    this.resetActiveStep();

    await this.learningRootService.unitResult.finishUnit({
      unitResultId: currentUnitResultSearch.data[0].id as string,
    });
  };

  onRedirectTo = async () => {
    await this.finishPassing();
    this.ui.isExpanded.setValue(false);
    this.ui.isFullScreen.setValue(false);
    this.ui.navigationList.list[this.ui.navigationList.list.length - 1].passed = true;
    this.ui.answersList.setList([]);
    this.redirect('finalResults');
  };

  navigationRedirect = (indexTitle: string) => {
    if (this.ui.pickedLang.value) {
      this.redirectInNavigation('practice');
      this.ui.stepsTitles.list.forEach((title, index) => {
        if (indexTitle === title) {
          this.ui.activeStep.setValue(index);
        }
      });
    }
  };
  pickingLang = (name: string) => {
    this.ui.pickedLang.setValue(name);
    this.ui.isExpanded.setValue(true);
    const contest = this.ui.passingTaskList.list.map((task) => {
      const activeLang = task.languages.find((lang) => lang.name === name) || task.languages[0];
      return {
        component: (
          <PassingTask
            task={task}
            activeLang={activeLang}
            passingCodeEditorDomain={this.passingCodeEditorDomain}
            rootDomain={this}
          />
        ),
        isEnable: true,
      };
    });
    this.ui.stepContent.setList([...this.ui.stepContent.list.slice(0, 1), ...contest]);
  };

  expandHandler = () => {
    this.ui.isExpanded.setValue(!this.ui.isExpanded.value);
  };
  addAnswer = (task: ITask, option: string, pickedLang: string, isInclude: boolean, file: IFile) => {
    for (let language of task.languages) {
      if (language.name === pickedLang) {
        const answerIndex = this.ui.answersList.list.findIndex((answer) => answer.task === task.pseudoId);
        if (answerIndex !== -1) {
          const answer = this.ui.answersList.list[answerIndex];

          if (isInclude) {
            if (!answer.correctOption.includes(option)) {
              answer.correctOption.push(option);
            }
          } else {
            const optionIndex = answer.correctOption.indexOf(option);
            if (optionIndex !== -1) {
              answer.correctOption.splice(optionIndex, 1);
            }
          }
        }
      }
    }
  };

  preCreateTaskList = () => {
    this.ui.answersList.setList([]);
    this.ui.passingTaskList.list.forEach((task) => {
      const taskLastPassingDate = this.ui.currentUnitResult.value.practiceResult.tasks?.filter(
        (taskResult) => taskResult.task === task.pseudoId,
      );

      let newAnswer = {
        task: task.pseudoId,
        correctOption:
          taskLastPassingDate && taskLastPassingDate.length !== 0 ? taskLastPassingDate[0].correctOption : [],

        result: taskLastPassingDate && taskLastPassingDate.length !== 0 ? taskLastPassingDate[0].result : 0,
        lastPassingDate:
          taskLastPassingDate && taskLastPassingDate.length !== 0 ? taskLastPassingDate[0].lastPassingDate : null,
      };
      const canCheck = this.canCheckTask(newAnswer);
      if (canCheck) {
        newAnswer.correctOption = [];
      }
      this.ui.answersList.list.push(newAnswer);
    });
  };

  getOption = (option: string) => {
    this.ui.correctOption.setValue(option);
  };

  setCorrectOptionForPickedLanguage = (task: ITask, pickedLang: string, optionName: string, isChecked: boolean) => {
    for (let language of task.languages) {
      if (language.name === pickedLang) {
        for (let file of language.files) {
          this.setCorrectOptionForFile(file, optionName, isChecked);
        }
        break;
      }
    }
    if (isChecked) {
      this.ui.correctOption.setValue(optionName);
    } else {
      this.ui.correctOption.setValue('');
    }
  };

  setCorrectOptionForFile = (file: IFile, optionName: string, isChecked: boolean) => {
    if (file.isQuestion && file.options) {
      file.options.forEach((option) => {
        if (option.name === optionName) {
          option.isCorrect = isChecked;
        }
      });
    }
    if (file.children) {
      file.children.forEach((childFile) => {
        this.setCorrectOptionForFile(childFile, optionName, isChecked);
      });
    }
  };
}
