import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { AnalyticsDomain } from '../store/AnalyticsDomain';

// import { AnalyticsPageTabs } from './AnalyticsPageTabs';

export const AnalyticsPageHeader = observer(({ domain }: { domain: AnalyticsDomain }) => {
  return (
    <>
      <Grid container marginBottom={'24px'}>
        <Grid container direction={'column'}>
          <Grid item fontSize={16}>
            Показатели процесса управления требованиями и знаниями по ИБ
          </Grid>
          <Grid item fontSize={48}>
            Аналитика
          </Grid>
        </Grid>
        {/* <AnalyticsPageTabs domain={domain} /> */}
      </Grid>
    </>
  );
});
