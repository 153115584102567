import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { getLocaleByCount } from '../../../../../utils/localeByCount';
import { PracticeIcons } from '../../passingListPage/icons/PracticeIcon';
import { TimeIcon } from '../../passingListPage/icons/TimeIcon';
import { UnitIcon } from '../../passingListPage/icons/UnitIcon';
import { formatTime } from '../../passingListPage/parts/utils/formatTime';

type Props = {
  unit: IUnitModel;
};

export const UnitCardStats = observer(({ unit }: Props) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {unit?.quiz.isEnabled && (
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <UnitIcon />
            <Box>{getLocaleByCount(unit?.quiz.questions.length, ['вопрос', 'вопроса', 'вопросов'])}</Box>
          </Box>
        )}
        {unit?.practice.isEnabled && (
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
            <PracticeIcons />
            <Box>{getLocaleByCount(unit?.practice.tasks.length, ['практика', 'практики', 'практик'])}</Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          <TimeIcon />
          <Box>
            {formatTime(
              Number(unit?.quiz.passageTime) + Number(unit?.practice.passageTime) + (unit?.theory.isEnabled ? 30 : 0),
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
