import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { inject } from '../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../service/route/IRouterService';
import { Colors } from '../../../design/color/Colors';
import { AnalyticsPageContent } from './parts/AnalyticsPageContent';
import { AnalyticsPageHeader } from './parts/AnalyticsPageHeader';
import { AnalyticsDomain } from './store/AnalyticsDomain';

export const AnalyticsPage = observer(({ layoutDomain }) => {
  const [analyticsDomain] = useState(new AnalyticsDomain(layoutDomain));
  const router = inject<IRouterService>(RouterServiceToken);

  const routes = {
    system: '/analytics/system',
  };

  const [tabValue, setTabValue] = useState('/analytics/system');
  const classes = useStyles();

  useEffect(() => {
    analyticsDomain.loadReqData();
  }, [analyticsDomain]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    router.goTo(newValue);
  };

  return (
    <Grid container display={'flex'} flexDirection={'column'} width={'100%'}>
      <AnalyticsPageHeader domain={analyticsDomain} />
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="analytics tabs" classes={{ indicator: classes.indicator }}>
            <Tab label="Системы" value={routes.system} />
          </TabList>
        </Box>
        <TabPanel value={routes.system}>
          <AnalyticsPageContent domain={analyticsDomain} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: Colors.selectedTabs('1'),
  },
}));
