import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Theme, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormActions } from '../../../../../../../design/form/FormActions';
import { FormAutocomplete } from '../../../../../../../design/form/FormAutocomplete';
import { FormCheckboxField } from '../../../../../../../design/form/FormCheckboxField';
import { FormTextField } from '../../../../../../../design/form/FormTextField';
import { IAdminPageProperties } from '../../../../../IAdminPageProperties';
import { ActiveDirectoryModalDomain } from './ActiveDirectoryModalDomain';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

export interface IActiveDirectoryServerAdminFormProperties extends IAdminPageProperties { }

export const ActiveDirectoryServerAdminForm = observer(
  ({ domain }: { domain: ActiveDirectoryModalDomain }) => {
    const classes = useStyles();
    const { serverId = null } = useParams<any>();

    useEffect(() => {
      domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать ` : `Добавить `);
      return () => {
        domain.ui.mappingValidationErrorsIndexes.setValue([])
      }
    }, [domain, serverId]);

    return (
      <Grid container className={classes.activeDirectoryModal} >
        <Grid className={classes.activeDirectoryModalHeader} >
          <Grid >
            Добавление Active Directory
          </Grid>
          <Grid className={classes.activeDirectoryModalHeaderCloseButton}>
            <IconButton
              data-cy={`close-modal`}
              color="secondary"
              aria-label="Закрыть окно"
              size='small'
              onClick={() => {
                domain.onCancelUpdates()
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid className={classes.activeDirectoryModalBody} >
          <ActiveDirectoryNameCustomFieldText domain={domain} />
          <ActiveDirectoryLdapCustomFieldText domain={domain} />
          <ActiveDirectoryLoginCustomFieldText domain={domain} />
          <ActiveDirectoryPasswordCustomFieldText domain={domain} />
          <ActiveDirectoryDNCustomFieldText domain={domain} />
          <ActiveDirectoryLoginMethodCustomCheckBox domain={domain} />
          <ActiveDirectoryActivateConnectionCustomCheckBox domain={domain} />
          <ActiveDirectoryUsersConnectionsBlock domain={domain} />
        </Grid>
        <Grid className={classes.activeDirectoryModalButtons}>
          <FormActions
            onSave={() => { domain.save() }}
            onCancel={() => {
              domain.onCancelUpdates()
            }}
            isLoading={domain.ui.isLoading.value} />
        </Grid>
      </Grid >
    );
  },
);

const ActiveDirectoryNameCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="Название"
      value={domain.ui.model.entity.name}
      onChange={domain.getUpdateFieldHandler('name')}
      required={true}
      errorMessage={domain.getValidationErrorFor('name')?.message}
      size={'small'}
      paddingSizing={1}
    />
  );
});

const ActiveDirectoryLdapCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="URL"
      value={domain.ui.model.entity.url || 'ldap://'}
      onChange={domain.getUpdateFieldHandler('url')}
      required={true}
      errorMessage={
        domain.getValidationErrorFor('url')?.message || domain.getValidationErrorFor('isConnected')?.message
      }
      size={'small'}
      paddingSizing={1}
    />
  );
});

const ActiveDirectoryLoginCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="Логин"
      value={domain.ui.model.entity.username}
      onChange={domain.getUpdateFieldHandler('username')}
      required={true}
      errorMessage={domain.getValidationErrorFor('username')?.message}
      size={'small'}
      paddingSizing={1}
    />
  );
});

const ActiveDirectoryPasswordCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="Пароль"
      value={domain.ui.model.entity.password}
      onChange={domain.getUpdateFieldHandler('password')}
      required={true}
      type={'password'}
      errorMessage={domain.getValidationErrorFor('password')?.message}
      size={'small'}
      paddingSizing={1}
    />
  );
});

const ActiveDirectoryDNCustomFieldText = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormTextField
      label="DN"
      value={domain.ui.model.entity.baseDN}
      onChange={domain.getUpdateFieldHandler('baseDN')}
      required={true}
      errorMessage={domain.getValidationErrorFor('baseDN')?.message}
      size={'small'}
      paddingSizing={1}
    />
  );
});

const ActiveDirectoryLoginMethodCustomCheckBox = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormCheckboxField
      label="Использовать аутентификацию с использованием данного AD по умолчанию"
      value={domain.ui.model.entity.isMainLoginMethod}
      defaultValue={false}
      onChange={domain.getUpdateFieldHandler('isMainLoginMethod')}
      errorMessage={domain.getValidationErrorFor('isMainLoginMethod')?.message}
      dataCy='choose-ad-auth-by-default'
    />
  );
});

const ActiveDirectoryActivateConnectionCustomCheckBox = observer(({ domain }: { domain: ActiveDirectoryModalDomain }) => {
  return (
    <FormCheckboxField
      onChange={domain.getUpdateFieldHandler('isActive')}
      label="Активировать данное подключение"
      value={domain.ui.model.entity.isActive}
      defaultValue={false}
      errorMessage={domain.getValidationErrorFor('isMainLoginMethod')?.message}
      dataCy='activate-connection'
    />
  );
});

const ActiveDirectoryUsersConnectionsBlock = observer((
  { domain }: { domain: ActiveDirectoryModalDomain }
) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.bodyConectionsBlock}>
      <Grid item>
        <Button
          onClick={() => {
            if (!domain.ui.model.entity.systemRoleToAdGroup?.mapping) {
              domain.ui.model.entity.systemRoleToAdGroup = { mapping: [] };
            }
            domain.ui.model.entity.systemRoleToAdGroup.mapping.unshift({ systemRoleId: null, groupName: '' });
            const errorIndexes = domain.ui.mappingValidationErrorsIndexes.value.map(item => item + 1)
            domain.ui.mappingValidationErrorsIndexes.setValue(errorIndexes)
          }}
          startIcon={<AddIcon />}
          style={{ color: '#2196F3' }}
        >
          Добавить связь
        </Button>
      </Grid>
      {domain.ui.model.entity?.systemRoleToAdGroup?.mapping?.map((item, index) => {
        const mappingValidationErrorsIndexes = domain.ui.mappingValidationErrorsIndexes.value
        const isHaveValidationError = mappingValidationErrorsIndexes.includes(index)
        const numberClassName = isHaveValidationError ? classes.conectionsBlockPartNumber + ' ' + classes.conectionsBlockPartNumberError : classes.conectionsBlockPartNumber
        return (
          <Grid container key={`ADform-${index}`} className={classes.conectionsBlockPart}>
            <Grid className={classes.conectionsBlockPartNumberBlock} item>
              <Grid className={numberClassName} >
                {domain.ui.model.entity?.systemRoleToAdGroup?.mapping.length - index}
              </Grid>
            </Grid>
            <Grid item className={classes.conectionsBlockPartInput}>
              <FormAutocomplete
                values={domain.systemRoles.list.map((item) => ({ value: item.id, name: item.name }))}
                onChangeSelect={(newValue: any) => {
                  item.systemRoleId = newValue.value || null;
                  if (newValue.value) {
                    domain.ui.mappingValidationErrorsIndexes.setValue(
                      mappingValidationErrorsIndexes.filter(item => item !== index)
                    )
                  }
                }}
                selectedDefault={null}
                selected={item.systemRoleId}
                label="Системная роль"
                size={'small'}
                controlPadding={'8px 8px'}
                errorMessage={isHaveValidationError ? 'Необходимо заполнить оба поля' : ''}
              />
            </Grid>
            <Grid item className={classes.conectionsBlockPartInput}>
              <FormTextField
                value={item.groupName || ''}
                onChange={(newValue: any) => {
                  item.groupName = newValue || ''
                  if (newValue) {
                    domain.ui.mappingValidationErrorsIndexes.setValue(
                      mappingValidationErrorsIndexes.filter(item => item !== index)
                    )
                  }
                }}
                label="Группа пользователей"
                required={false}
                size={'small'}
                paddingSizing={1}
                isHaveErrors={isHaveValidationError}
              />
            </Grid>
            <Grid item className={classes.conectionsBlockPartDeleteButton}>
              <IconButton
                data-cy={`close-modal`}
                color="secondary"
                aria-label="Закрыть окно"
                size='small'
                onClick={() => {
                  domain.ui.model.entity.systemRoleToAdGroup.mapping = domain.ui.model.entity.systemRoleToAdGroup.mapping.filter(
                    (link, itemIndex) => {
                      return itemIndex !== index;
                    },
                  );
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
    </Grid >
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  activeDirectoryModal: {
    width: 700,
    minHeight: '500px',
    maxHeight: '80%',
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24
  },
  activeDirectoryModalHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '100%',
    marginBottom: '12px',

  },
  activeDirectoryModalHeaderCloseButton: {
    position: 'absolute',
    right: 24,
  },
  activeDirectoryModalBody: {
    overflowY: 'scroll',
    height: '90%',
    maxHeight: '700px',
    width: '100%'
  },
  activeDirectoryModalButtons: {
    width: 'calc(100% + 48px)',
    borderTop: '1px solid #FFFFFF1A',
    marginLeft: -24,
    marginRight: -24,
    paddingRight: 24
  },
  bodyConectionsBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    borderTop: '1px solid #FFFFFF1A'
  },
  conectionsBlockPart: {
    display: 'flex',
    width: '100%',
    marginTop: 8,
    justifyContent: 'start'
  },
  conectionsBlockPartNumberBlock: {
    width: '5%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  conectionsBlockPartNumber: {
    backgroundColor: '#FFFFFF0F',
    width: 24,
    height: 24,
    borderRadius: '4px',
    textAlign: 'center'
  },
  conectionsBlockPartNumberError: {
    border: '1px solid red'
  },
  conectionsBlockPartInput: {
    width: '45%'
  },
  conectionsBlockPartDeleteButton: {
    width: '5%',
    margin: 'auto'
  }
}));