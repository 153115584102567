import { RowContextMenuDomain } from '../../../../../../../design/dataTable/parts/rowContextMenu/domain/RowContextMenuDomain';
import { ActiveDirectoryAdminModel } from './../ActiveDirectoryAdminModel';
import React, { Fragment } from 'react';
import { IMainLayoutDomainStore } from '../../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { IRowContextMenuUI } from '../../../../../../../design/dataTable/parts/rowContextMenu/domain/IRowContextMenuUI';
import { ActiveDirectoryContextMenuUI } from './ActiveDirectoryContextMenuUI';
import { ConfirmModal } from '../../../../../../../design/modal/confirm/ConfirmModal';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { IActiveDirectoryServerEntityService } from '../../../../../../../../service/activeDirectory/server/IActiveDirectoryServerEntityService';
import { ProjectLink } from '../../../../../../../design/link/ProjectLink';
import { ActiveDirectoryAdminDomain } from '../ActiveDirectoryAdminDomain';

export class ActiveDirectoryContextMenuDomain extends RowContextMenuDomain<ActiveDirectoryAdminModel, IActiveDirectoryServerEntityService> {
  constructor({
    entityService,
    dataTableDomain,
    layoutDomain,
    ui,
  }: {
    entityService: IActiveDirectoryServerEntityService;
    dataTableDomain: ActiveDirectoryAdminDomain;
    layoutDomain: IMainLayoutDomainStore;
    ui?: IRowContextMenuUI<ActiveDirectoryAdminModel> | null;
  }) {
    super(entityService, dataTableDomain, layoutDomain, ui);
    this.ui = ui || new ActiveDirectoryContextMenuUI(this);
  }

  async deleteADConfirmation(row: any) {
    //@ts-ignore
    const connectedProjects = await this.entityService.tryToDeleteADConnection(row.id)

    if (connectedProjects.length === 0) {
      const Component = () => {
        return (
          <ConfirmModal
            title="Удалить профиль?"
            text={`Пользователей, участвующих в проектах, в данном LDAP-профиле нет. Вы уверены, что хотите удалить профиль ${row?.row?.name || row?.name}?`}
            onCancel={async () => {
              this.layoutDomain.modalWindow.closeModalWindow();
            }}
            onOk={async () => {
              await this.deleteById(row.id);
              await this.dataTableDomain.boot();
              //   @ts-ignore
              this.dataTableDomain.modalDomain.ui.model.setEntity({});
              this.layoutDomain.modalWindow.closeModalWindow();
            }}
          />
        );
      };

      return Component;
    } else {
      const Component = () => {
        return (
          <Fragment>
            <DialogTitle id="alert-dialog-title" style={{ fontSize: '24px' }}>
              {'Невозможно удалить профиль'}
            </DialogTitle>
            <DialogTitle id="alert-dialog-title" style={{ fontSize: '16px', paddingTop: 0 }}>
              {`В данном LDAP-профиле содержатся пользователи, участвующие в проектах. Для удаления профиля ${row.name} необходимо отвязать пользователей от проектов.`}
            </DialogTitle>
            <DialogTitle id="alert-dialog-title" style={{ fontSize: '16px', paddingTop: 0 }}>
              {'Пользователи, участвующие в проектах, в данном LDAP-профиле:'}
            </DialogTitle>
            <DialogContent>
              {connectedProjects.map((item: any) => {
                return (
                  <DialogContentText id="alert-dialog-description" key={`${item.user.id}`}>
                    {item.user.displayName} в проектах: {
                      item.projects.map((project: any, index: number) => (
                        <React.Fragment key={project.id}>
                          {index > 0 && ', '}
                          <ProjectLink onClick={() => this.layoutDomain.modalWindow.closeModalWindow()} url={`/project/${project.id}/info`}>{project.name}</ProjectLink>
                        </React.Fragment>
                      ))

                    }
                  </DialogContentText>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.layoutDomain.modalWindow.closeModalWindow()} color="secondary">
                Ок
              </Button>
            </DialogActions>
          </Fragment>
        );
      };

      return Component;
    }

  };

  async onDeleteRow(row: ActiveDirectoryAdminModel) {
    const confirmComponent = await this.deleteADConfirmation(row)
    this.layoutDomain.modalWindow.showModalWindow(confirmComponent);
    const activeUrl = this.router.getActiveUrl();
    this.dataTableDomain.loadList()
    this.router.goTo(activeUrl);
  }
}
