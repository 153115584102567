import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import { PassingContext } from '../../../../PassingContext';
import { PassingSidebarAccordion } from '../PassingSidebarAccordion';
import { PassingTheoryAccordionContent } from './PassingTheoryAccordionContent';

export const PassingSidebarTheory = observer(() => {
  const { passingDomain, theoryDomain, redirectToStep } = useContext(PassingContext);

  if (!theoryDomain.ui.isEnebled.value) {
    return <></>;
  }

  return (
    <PassingSidebarAccordion
      onChange={passingDomain.theoryDomain.expandHandler}
      isExpanded={passingDomain.theoryDomain.ui.isExpanded.value}
      onClick={(e) => {
        redirectToStep(e, 'theory');
      }}
      title="Теория"
      timeLimitLabel="30 мин"
    >
      {passingDomain.theoryDomain.ui.navigationList.list.map((chapter, index) => {
        return (
          <PassingTheoryAccordionContent
            isActiveStep={passingDomain.theoryDomain.ui.activeStep.value === index ? true : false}
            title={chapter.title}
            isActive={chapter.isActive}
            passed={chapter.passed}
            subHeader={chapter.subHeader}
            navigationRedirect={chapter.navigationRedirect}
            hash={passingDomain.theoryDomain.ui.hash.value}
            scrollToAnchor={passingDomain.theoryDomain.scrollToAnchor}
            activeAccordionTitle={passingDomain.theoryDomain.ui.activeAccordionTitle.value}
            onExpandIconClick={passingDomain.theoryDomain.onExpandIconClick}
            dataCy={chapter.title}
          />
        );
      })}
    </PassingSidebarAccordion>
  );
});
