import { NotificationType } from '../../../../../../../service/notification/INotificationModel';

export const notificationMapValues = [
  // {
  //   value: NotificationType.CREATE_SYSTEM_ROLE,
  //   name: 'Уведомление о создании новой системной или проектной роли',
  // },
  {
    value: NotificationType.CHANGE_STATUS,
    name: 'Уведомление о переводе всех существующих “Статусов” для требований в одно из существующих значений',
  },
  // {
  //   value: NotificationType.CREATE_ADMIN,
  //   name: 'Уведомление добавлении нового администратора (в т.ч. локального администратора)',
  // },
  {
    value: NotificationType.UPSERT_PROJECT,
    name: 'Создание нового / Изменение текущего проекта',
  },
  {
    value: NotificationType.CREATE_APPLICATION,
    name: 'Создание новой Анкеты АС в Проекте',
  },
  {
    value: NotificationType.EDIT_APPLICATION,
    name: 'Изменение существующей Анкеты АС в Проекте',
  },
  {
    value: NotificationType.TRANSFER_APPLICATION,
    name: 'Перенос Анкеты АС в другой Проект',
  },
  {
    value: NotificationType.COPY_APPLICATION,
    name: 'Копирование Анкеты АС в другой Проект',
  },
  {
    value: NotificationType.REQUIREMENT_ACCEPT,
    name: 'Обновление набора требований АС после изменения шаблонов требований',
  },
  // {
  //   value: NotificationType.CREATE_COMMENT_JIRA,
  //   name: 'Создание нового комментария к требованию в Jira',
  // },
  // {
  //   value: NotificationType.CREATE_REQUIREMENT,
  //   name: 'Создание нового требования в АС',
  // },
  {
    value: NotificationType.CREATE_TEMPLATE,
    name: 'Создание нового Шаблона требований',
  },
  {
    value: NotificationType.EDIT_REQUIREMENT_TEMPLATE,
    name: 'Обновление Шаблона требования',
  },
  {
    value: NotificationType.DELETE_REQUIREMENT_TEMPLATE,
    name: 'Удаление Шаблона требования',
  },
  // {
  //   value: NotificationType.EDIT_ADDITIONAL_COLUMN_CONTENT,
  //   name: 'Изменение содержимого доп колонки',
  // },
  // {
  //   value: NotificationType.EDIT_PASSWORD,
  //   name: 'Уведомление об изменении пароля локальных УЗ',
  // },
  // {
  //   value: NotificationType.EDIT_REQUIREMENT,
  //   name: 'Уведомление об изменении требования',
  // },
  // {
  //   value: NotificationType.EDIT_ROLES_GROUP,
  //   name: 'Уведомление об изменении состава групп (изменение в ролевой модели, добавление пользователей)',
  // },
  // {
  //   value: NotificationType.UPSERT_ADDITIONAL_COLUMN,
  //   name: 'Уведомление об изменении/ добавлении доп.колонок',
  // },
  // {
  //   value: NotificationType.INCLUDE_EXCLUDE_REQUIREMENT,
  //   name: 'Принятие/исключение требований в уже заполненных анкетах (красные/ синие маркеры)',
  // },

];
