import { IProjectsWithUserStats } from "../../../admin/page/passingListPage/store/IProjectsWithUserStats";

interface CsvDataRow {
    project_ID: string;
    project_name: string;
    user_id: string;
    user_name: string;
    user_email: string;
    project_role: string;
    unit_name: string;
    theory_result: number | string;
    quiz_result: number | string;
    practice_result: number | string;
    unit_max: number | string;
}


export const formatDataForCSV = (projectsWithUserStats: IProjectsWithUserStats[]) => {
    let csvData:CsvDataRow[] = [];

    projectsWithUserStats.forEach(project => {
        project.users.forEach(user => {
            user.unitResults.forEach(unitResult => {
                const unitDetails = project.importantUnits.find(unit => unit.id === unitResult.unitId);
                if (unitDetails) {
                    csvData.push({
                        project_ID: project.project.id,
                        project_name: project.project.name,
                        user_id: user.user.id,
                        user_name: user.user.fullName,
                        user_email: user.user.email,
                        project_role: user.user.roleName,
                        unit_name: unitDetails.settings.titleHeading,
                        theory_result: unitResult.theoryResult?.totalTheoryResult || 0,
                        quiz_result: unitResult.quizResult?.totalQuizResult || 0,
                        practice_result: unitResult.practiceResult?.totalPracticeResult || 0,
                        unit_max: unitDetails.score || 0
                    });
                } else {
                    csvData.push({
                        project_ID: project.project.id,
                        project_name: project.project.name,
                        user_id: user.user.id,
                        user_name: user.user.fullName,
                        user_email: user.user.email,
                        project_role: user.user.roleName,
                        unit_name: '',
                        theory_result: '',
                        quiz_result: '',
                        practice_result: '',
                        unit_max: ''
                    });
                }
            });
            if (user.unitResults.length === 0) {
                csvData.push({
                    project_ID: project.project.id,
                    project_name: project.project.name,
                    user_id: user.user.id,
                    user_name: user.user.fullName,
                    user_email: user.user.email,
                    project_role: user.user.roleName,
                    unit_name: '',
                    theory_result: '',
                    quiz_result: '',
                    practice_result: '',
                    unit_max: ''
                });
            }
        });
    });

    return csvData;
};


export const downloadCSV = (data:CsvDataRow[]) => {
    const csvHeader = "project_ID,project_name,user_id,user_name,user_email,project_role,unit_name,theory_result,quiz_result,practice_result,unit_max\n";
    const csvRows = data.map(item =>
        [
            item.project_ID,
            `"${item.project_name}"`,
            item.user_id,
            `"${item.user_name}"`,
            item.user_email,
            item.project_role,
            `"${item.unit_name}"`,
            item.theory_result,
            item.quiz_result,
            item.practice_result,
            item.unit_max
        ].join(",")
    ).join("\n");

    const utf8WithBom = new Uint8Array([0xEF, 0xBB, 0xBF, ...new TextEncoder().encode(csvHeader + csvRows)]);
    const blob = new Blob([utf8WithBom], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'export.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

