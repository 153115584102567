import { getLocaleByCount } from "../../../../../../utils/localeByCount";

export const formatTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const hourLocales = ["час", "часа", "часов"];
  const minuteLocales = ["минута", "минуты", "минут"];
  let result = "";
  
  if (hours > 0) {
      result += getLocaleByCount(hours, hourLocales);
  }

  if (remainingMinutes > 0) {
      if (result.length > 0) result += " ";
      result += getLocaleByCount(remainingMinutes, minuteLocales);
  }

  return result;
};
