import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { UnitCardHeader } from './UnitCardHeader';
import { UnitCardLangs } from './UnitCardLangs';
import { UnitCardStats } from './UnitCardStats';

const CARD_COLORS = {
  baseColor: 'rgba(144, 202, 249, 0.03)',
  color: '#FFFFFF',
  borderColor: 'rgba(144, 202, 249, 0.08)',
  progressColor: '#90CAF9',
  hoverColor: 'rgba(144, 202, 249, 0.08)',
};

export interface IAdminUnitCardProps {
  name: string;
  isPublished: string;
  id: string;
  image: string;
}

export interface IUnitListAdminCardProperties {
  unit: IUnitModel;
  handleEdit: (unitId: string) => void;
  handleDelete: (unitId: string) => void;
  handlePublish: (unitId: string) => void;
  handleUnpublish: (unitId: string) => void;
}

export const UnitListAdminCard = observer(
  ({ unit, handleEdit, handleDelete, handlePublish, handleUnpublish }: IUnitListAdminCardProperties) => {
    return (
      <Box
        sx={{
          background: CARD_COLORS.baseColor,
          borderColor: CARD_COLORS.borderColor,
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '16px',
          position: 'relative',
          cursor: 'pointer',
          '&:hover': {
            background: CARD_COLORS.hoverColor,
          },
        }}
      >
        <Box
          id={unit.id}
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '0 0 70%',
            }}
          >
            <Box sx={{ padding: '24px 24px 0 24px' }}>
              <UnitCardHeader
                unit={unit}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handlePublish={handlePublish}
                handleUnpublish={handleUnpublish}
              />
              <UnitCardStats unit={unit} />
              <UnitCardLangs unit={unit} />
            </Box>
          </Box>
          <Box
            sx={{
              flex: '0 0 30%',
              minHeight: '140px',
              position: 'relative',
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px',
              overflow: 'hidden'
            }}
          >
            {
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                src={unit.settings.preview?.data || '/pictures/unit-cover-default.png'}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            }
          </Box>
        </Box>
      </Box>
    );
  },
);
