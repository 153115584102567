import { inject } from '../../../../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { DataTableDomain } from '../../../../../../design/dataTable/store/DataTableDomain';
import { ActiveDirectoryAdminUI } from './ActiveDirectoryAdminUI';
import { ActiveDirectoryContextMenuDomain } from './context/ActiveDirectoryContextMenuDomain'
import { ActiveDirectoryModalDomain } from './modal/ActiveDirectoryModalDomain';
import { ActiveDirectoryServerAdminModel } from './modal/ActiveDirectoryServerAdminModel';
import TableHeader from '../../../../../../design/dataTable/parts/appBar/TableHeader'
import { DefaultTableSlots } from '../../../../../../design/dataTable/store/DataTableUI';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';

export class CustomAppBar {
  Toolbar = TableHeader;
}

export class ActiveDirectoryAdminDomain extends DataTableDomain<ActiveDirectoryServerAdminModel, any> {
  public ui: ActiveDirectoryAdminUI;
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
    public router: IRouterService = inject<IRouterService>(RouterServiceToken),
  ) {
    super(layoutDomain, rootPrivateServices.activeDirectory.server);
    this.ui = new ActiveDirectoryAdminUI(this, null);
    this.contextMenuDomain = new ActiveDirectoryContextMenuDomain({
      entityService: rootPrivateServices.activeDirectory.server,
      dataTableDomain: this,
      layoutDomain,
    });
    this.modalDomain = new ActiveDirectoryModalDomain(rootPrivateServices.activeDirectory.server, layoutDomain, this);
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.ui.tableTitle.setValue('Интеграции');
    this.ui.tableSubtitle.setValue('Active Directory');
  }

  async boot() {
    super.boot()
    this.setPermissions()
  }

  async setPermissions() {
    super.setPermissions();
    this.ui.isCanCreate.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-create']]),
    );
    this.ui.isCanDelete.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-delete']]),
    );
    this.ui.isCanEdit.setValue(
      this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['settings-integrations-update']]),
    );
  }

  async loadData() {
    this.ui.getColumns()
    await super.loadData();
  }

}
