import React from 'react';
import { observer } from 'mobx-react';
import {
  ApplicationInfoReport,
  IApplicationInfoReportProperties,
} from '../../../../view/user/page/application/info/parts/report/ApplicationInfoReport';

export const ApplicationInfoReportWithColor = observer(
  ({ domain, audit, isNeedColor, listWithAudits, fullVersion, application }: IApplicationInfoReportProperties) => {
    return (
      <ApplicationInfoReport
        domain={domain}
        audit={audit}
        isNeedColor={true}
        listWithAudits={listWithAudits}
        fullVersion={fullVersion}
        application={application}
      />
    );
  },
);
