import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { IUnitResultModel } from '../../../../../service/unitResult/IUnitResultModel';
import { UnitCardHeader } from './components/UnitCardHeader';
import { UnitCardLangs } from './components/UnitCardLangs';
import { UnitCardStats } from './components/UnitCardStats';
import { UNIT_LIST_ITEMS_STYLES, getUnitStatus } from './utils/passingListUserCard';

export interface IUserUnitCardProperties {
  unit: IUnitModel;
  unitResult: IUnitResultModel;
  action: any;
  isPassed: boolean | null;
  status: 'unimportant' | 'important';
}

export const UserUnitCard = observer(({ action, unitResult, isPassed, status, unit }: IUserUnitCardProperties) => {
  const unitStatus = getUnitStatus(isPassed);
  const dataCyUniqueValue = `data-cy-w${unit.id}`;

  return (
    <Box
      data-cy={dataCyUniqueValue}
      sx={{
        background: UNIT_LIST_ITEMS_STYLES[unitStatus].baseColor,
        borderColor: UNIT_LIST_ITEMS_STYLES[unitStatus].borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          background: UNIT_LIST_ITEMS_STYLES[unitStatus].hoverColor,
        },
      }}
    >
      <Box
        id={unit.id}
        onClick={() => {
          action(unit.id);
        }}
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: '0 0 70%',
          }}
        >
          <Box sx={{ padding: '24px 24px 0 24px' }}>
            <UnitCardHeader unit={unit} status={status} />
            <UnitCardStats unitResult={unitResult} unit={unit} />
            <UnitCardLangs unit={unit} />
          </Box>
        </Box>
        <Box sx={{ flex: '0 0 30%', minHeight: '140px', position: 'relative' }}>
          {
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src={unit.settings.preview?.data || '/pictures/unit-cover-default.png'}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          }
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          borderBottom: `4px solid ${UNIT_LIST_ITEMS_STYLES[unitStatus].progressColor}`,
          width: `${(unitResult.totalUnitResult * 100) / unit.score}%`,
        }}
      ></Box>
    </Box>
  );
});
