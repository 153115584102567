import { ConstructorType, inject, injectValue } from '../../../../../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../../../../../service/route/IRouterService';
import { injectRootService } from '../../../../../../../../service/RootServiceFactory';
import { ApplicationRequirementFilterDomain } from '../../../store/filter/ApplicationRequirementFilterDomain';
import { IContainer } from '../../../../../../../../common/container/IContainer';
import { ApplicationDetailDomain } from '../../../store/ApplicationDetailDomain';
import { ApplicationHistoryUI, IApplicationModelWithUser } from './ApplicationHistoryUI';
import {
  ApplicationAcceptStatus,
  IApplicationModel,
} from '../../../../../../../../service/application/entity/IApplicationModel';
import {
  IApplicationChanges,
  ICustomInformationChange,
  IRequirementChanges,
  ISpecificationChanges,
} from './IApplicationChanges';
import { ISpecificationEntityModel } from '../../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { IRequirementModel } from '../../../../../../../../service/requirement/entity/IRequirementModel';

export class ApplicationHistoryDomain {
  constructor(
    public rootDomain: ApplicationDetailDomain,
    public ui: ApplicationHistoryUI = new (injectValue<ConstructorType<ApplicationHistoryUI>>(ApplicationHistoryUI))(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    protected rootService = injectRootService(rootDomain.layoutDomain.serviceType.value),
    filterDomain: ApplicationRequirementFilterDomain | null = null,
    private container: IContainer | null = null,
    private applicationChangesCache: Map<string, IApplicationChanges> = new Map(),
    public clearThirdVersionAudits: (renderList: IApplicationModel[]) => IApplicationModel[] = (renderList) => { return renderList; },
  ) { }

  async boot() {
    let fullAuditList = await this.rootService.application.entity.getAuditListById(
      this.rootDomain.ui.application.entity.id || '',
    );
    if (!fullAuditList.length) {
      fullAuditList = [
        await this.rootService.application.entity.getById(this.rootDomain.ui.application.entity.id || ''),
      ];
    }

    let renderAuditList = fullAuditList.filter((audit) => {
      return audit.versionNumber?.split('.')[2] === '0'; // && audit.versionNumber !== '0.0.0';
    });

    if (
      this.rootDomain.ui.application.entity.acceptStatus === ApplicationAcceptStatus.accepted
    ) {
      if (
        this.rootDomain.ui.application.entity.versionNumber?.split('.')[2] !== '0' ||
        this.rootDomain.ui.application.entity.versionNumber?.split('.')[2] === renderAuditList[0].versionNumber
      ) {
        renderAuditList.shift();
      }
      renderAuditList = this.clearThirdVersionAudits(renderAuditList);

      const lastNumberAudit = fullAuditList[0].versionNumber?.split('.') || [0, 0];
      lastNumberAudit[2] = '';
      const lastNumberEntity = this.rootDomain.ui.application.entity.versionNumber?.split('.') || [0, 0];
      lastNumberEntity[2] = '';

      renderAuditList[0] = {
        ...this.rootDomain.ui.application.entity,
        versionNumber: lastNumberEntity.join('.') || lastNumberAudit.join('.'),
        auditUserId: renderAuditList[0]?.auditUserId || fullAuditList[0].auditUserId,
      };
    } else {
      // TODO remove and fix number
      const version = renderAuditList[0]?.versionNumber?.split('.');
      if (version) {
        if (Number(version[1]) === 0) {
          renderAuditList[0].versionNumber = [Number(version[0]) + 1, version[1]].join('.');
        }
        if (renderAuditList.length > 1) {
          const nextElementVersion = renderAuditList[1]?.versionNumber?.split('.');
          if (nextElementVersion && version) {
            if (nextElementVersion[0] === version[0] && nextElementVersion[1] === version[1]) {
              renderAuditList[0].versionNumber = [Number(version[0]) + 1, 0].join('.');
            }
          }
        }
      }
    }

    // TODO fix sort order
    renderAuditList[0].auditDate = renderAuditList[0]?.auditDate?.getTime()
      ? new Date(renderAuditList[0]?.auditDate?.getTime() + 10 * 1000)
      : renderAuditList[0]?.auditDate;

    const auditListWithUser: IApplicationModelWithUser[] = [...renderAuditList];
    const version = auditListWithUser[0]?.versionNumber?.split('.');
    if (version) {
      auditListWithUser[0].versionNumber = [Number(version[0]), version[1]].join('.');
    }
    let counter = 0;

    for (let i = 0; i < auditListWithUser.length - 1; i++) {
      const audit = auditListWithUser[i];
      const nextAudit = auditListWithUser[i + 1];
      if (counter !== 0 && audit?.versionNumber && nextAudit.versionNumber) {
        const version = audit?.versionNumber?.split('.');
        const nextVersion = nextAudit?.versionNumber?.split('.');

        const majorVersionCurrent = Number(version[0]);
        const minorVersionCurrent = Number(version[1]);

        const majorVersionNext = Number(nextVersion[0]);
        const minorVersionNext = Number(nextVersion[1]);

        if (minorVersionCurrent === 0) {
          audit.versionNumber = [majorVersionCurrent + 1, minorVersionCurrent].join('.');
        } else if (majorVersionCurrent === majorVersionNext && minorVersionNext === minorVersionCurrent) {
          audit.versionNumber = [majorVersionCurrent + 1, 0].join('.');
        } else {
          audit.versionNumber = [majorVersionCurrent, minorVersionCurrent].join('.');
        }
      }
      counter++;

      if (audit.auditUserId) {
        const user = await this.rootService.user.entity.getById(audit.auditUserId);
        audit.auditUser = {
          login: user.login,
          displayName: user.displayName,
          email: user.email,
        };
      }
    }
    if (auditListWithUser.length > 1) {
      const lastListedAudit = auditListWithUser[auditListWithUser.length - 1];
      if (lastListedAudit.auditUserId) {
        const user = await this.rootService.user.entity.getById(lastListedAudit.auditUserId);
        lastListedAudit.auditUser = {
          login: user.login,
          displayName: user.displayName,
          email: user.email,
        };
      }
      const lastVersion = lastListedAudit?.versionNumber?.split('.');
      if (lastVersion) {
        lastListedAudit.versionNumber = [Number(lastVersion[0]) + 1, lastVersion[1]].join('.');
      }
    }
    this.ui.auditApplications.setList([...auditListWithUser]);
    this.ui.fullAuditApplications.setList([...fullAuditList]);
    await this.selectApplication(auditListWithUser[0]);
  }

  async selectApplication(application: IApplicationModel) {
    const isExists = this.ui.selectedApplicationsIds.value.includes(application.id || '');
    if (isExists) {
      this.ui.selectedApplicationsIds.setValue(
        this.ui.selectedApplicationsIds.value.filter((id) => id !== application.id),
      );
      const newSelectedApplicationsSortedList = this.ui.selectedAuditApplication.list.filter(
        (item) => item.id !== application.id,
      );
      newSelectedApplicationsSortedList.sort((a, b) => {
        return (b.auditDate?.getTime() || 0) - (a.auditDate?.getTime() || 0);
      });
      this.ui.selectedAuditApplication.setList(newSelectedApplicationsSortedList);
    } else {
      this.ui.selectedApplicationsIds.value.push(application.id || '');
      let newSelectedApplicationsSortedList = [...this.ui.selectedAuditApplication.list, application];
      newSelectedApplicationsSortedList = newSelectedApplicationsSortedList.sort((a, b) => {
        return (b.auditDate?.getTime() || 0) - (a.auditDate?.getTime() || 0);
      });
      this.ui.selectedAuditApplication.setList(newSelectedApplicationsSortedList as IApplicationModelWithUser[]);
    }

    await this.calculateChanges();
  }

  async calculateCustomInformationChange({
    applicationBefore,
    application,
  }: {
    applicationBefore: IApplicationModelWithUser;
    application: IApplicationModelWithUser;
  }) {
    const mapAsSame = (field) => {
      return {
        customField: field,
        customFieldBefore: field,
        isChanged: false,
      };
    };
    const nullFilterCB = (item) => {
      return item !== null;
    };

    const customFieldsBefore = applicationBefore?.customInformation?.fields || [];
    const customFields = application?.customInformation?.fields || [];

    if (applicationBefore?.customInformation?.fields === undefined) {
      return customFields.map(mapAsSame).filter((item) => item.customField.value !== '');
    }

    let customInformationChanges = customFields
      .map((field): ICustomInformationChange => {
        const fieldBefore = customFieldsBefore.find((f) => f.fieldName === field.fieldName);
        const isChanged = fieldBefore?.value !== field.value;

        const isEmptyValues = field.value === '' && fieldBefore?.value === '';
        if (isEmptyValues) {
          return null;
        }

        return {
          customField: field,
          customFieldBefore: fieldBefore || {
            fieldName: field.fieldName,
            value: '',
            fieldDescription: field.fieldDescription,
            sortIndex: field.sortIndex,
            type: field.type,
          },
          isChanged: isChanged,
        };
      })
      .filter(nullFilterCB);

    return customInformationChanges;
  }

  calculateCustomFieldsCounter(customInformationChange: ICustomInformationChange, customInformationCounter): void {
    if (customInformationChange?.customField?.value !== '') {
      customInformationCounter.saved++;
    }

    if (customInformationChange?.customFieldBefore?.value === '') {
      customInformationCounter.newest++;
    }

    if (customInformationChange?.customField?.value === '') {
      customInformationCounter.removed++;
    }

    if (customInformationChange?.isChanged) {
      customInformationCounter.edited++;
    }
  }

  async calculateChanges() {
    const applicationsChanges: IApplicationChanges[] = [];
    const selectedReversedApplciations = this.ui.selectedAuditApplication.list.slice().reverse();
    for (let strIndex in selectedReversedApplciations) {
      const index = Number(strIndex);
      let application = selectedReversedApplciations[index];
      const applicationChanges: IApplicationChanges = {
        applicationData: null,
        requirementsChanges: [],
        specificationsChanges: [],
        customInformationChanges: [],
        application: null,
        isSupport: {
          compareApplicationChanges: false,
          compareRequirement: false,
          compareSpecification: false,
        },
        compareWithApplicationChanges: null,
        allRequirements: [],
        allSpecifications: [],
        counters: {
          requirements: {
            newest: 0,
            removed: 0,
            saved: 0,
          },
          specifications: {
            newest: 0,
            removed: 0,
            saved: 0,
          },
          customFields: {
            newest: 0,
            removed: 0,
            edited: 0,
            saved: 0,
          },
        },
      };
      const isOldestVersion = index === 0;
      const compareApplicationChanges = isOldestVersion ? null : applicationsChanges[index - 1];
      const cachedApplicationChanges = this.applicationChangesCache.get(
        `${application?.id}-${compareApplicationChanges?.application?.id}`,
      );
      if (cachedApplicationChanges) {
        return cachedApplicationChanges;
      }

      const customInfoChange = await this.calculateCustomInformationChange({
        application,
        applicationBefore: selectedReversedApplciations[index - 1],
      }).catch(() => []);

      customInfoChange?.forEach((field, index) => {
        this.calculateCustomFieldsCounter(field, applicationChanges.counters.customFields);
        applicationChanges.customInformationChanges.push(field);
      });
      applicationChanges.counters.customFields.edited -= Math.abs(
        applicationChanges.counters.customFields.removed - applicationChanges.counters.customFields.newest,
      );

      const auditSettings = {
        auditDateInMS: (application?.auditDate?.getTime() || 0) + 10 * 1000,
        isReplaceId: true,
      };
      const applicationData = await this.rootService.application.data
        .getById(application?.dataId || '', auditSettings)
        .catch(() => null);
      applicationChanges.applicationData = applicationData;
      applicationChanges.application = application;
      applicationChanges.isSupport.compareApplicationChanges = !!compareApplicationChanges;
      applicationChanges.compareWithApplicationChanges = compareApplicationChanges;
      applicationChanges.isSupport.compareRequirement = !!applicationData;
      applicationChanges.isSupport.compareSpecification = !!application;

      // TODO разобраться с аудитами
      const [allRequirements, allSpecifications] = await Promise.all([
        this.rootService.requirement.entity.search({
          limit: 10000,
          audit: auditSettings,
        }),
        this.rootService.specification.entity.search({
          limit: 10000,
          // audit: auditSettings,
        }),
      ]);

      const localRequirements = await this.rootService.requirement.entity.search({
        limit: 10000,
        audit: auditSettings,
        // @ts-ignore
        filter: [{ fieldName: 'applicationId', type: 'equal', value: application.id }]
      });

      const localRequirementsIds = localRequirements.data.map(requirement => requirement.id)
      applicationChanges.allRequirements = allRequirements?.data || [];
      if (applicationData?.savedRequirementsIds) {
        applicationData.savedRequirementsIds = [...applicationData.savedRequirementsIds, ...localRequirementsIds] as string[];
      }
      applicationChanges.allSpecifications = allSpecifications?.data || [];

      let applicationRequirements: IRequirementModel[] = [];

      if (applicationChanges.isSupport.compareRequirement) {
        applicationRequirements = applicationChanges.allRequirements.filter((requirement) =>
          (applicationData?.savedRequirementsIds || []).includes(requirement.id || ''),
        );
        console.log(
          'requirements and counts',
          'saved',
          applicationData?.savedRequirementsIds,
          'deleted',
          applicationData?.deletedRequirementsIds,
          'newest',
          applicationData?.newestRequirementsIds,
        );
        console.log('applicationRequirements: ', applicationRequirements);
        console.log('applicationChanges.allRequirements: ', applicationChanges.allRequirements);
        applicationChanges.counters.requirements.saved = applicationRequirements.length;
      }

      let applicationSpecifications: ISpecificationEntityModel[] = [];
      if (applicationChanges.isSupport.compareSpecification) {
        applicationSpecifications = applicationChanges.allSpecifications.filter((specification) =>
          (application?.specificationsIds || []).includes(specification.id || ''),
        );
        applicationChanges.counters.specifications.saved = applicationSpecifications.length;
      }

      if (applicationRequirements.length === 0 && (application?.specificationsIds?.length || 0) > 0) {
        applicationChanges.isSupport.compareSpecification = false;
      } else {
        applicationChanges.isSupport.compareSpecification = true;
      }

      const compareSpecification = (target: ISpecificationEntityModel, next: ISpecificationEntityModel) => {
        let isChanged = false;
        if (target.name !== next.name) {
          isChanged = true;
        }
        if (target.description !== next.description) {
          isChanged = true;
        }
        return isChanged;
      };

      applicationChanges.specificationsChanges = applicationSpecifications.map((specification) => {
        if (applicationChanges.isSupport.compareApplicationChanges) {
          const compareSpecificationChanges =
            (compareApplicationChanges?.specificationsChanges || []).find(
              (item) => item.specification.id === specification.id,
            ) || null;
          const isChanged =
            compareSpecificationChanges &&
            compareSpecification(specification, compareSpecificationChanges.specification);
          const isNew = !compareSpecificationChanges;
          const changes: ISpecificationChanges = {
            specification: specification,
            compareWithSpecificationChanges: compareSpecificationChanges,
            isChanged: !!isChanged,
            isNew,
            isRemoved: false,
          };
          if (isNew) {
            applicationChanges.counters.specifications.newest++;
          }
          return changes;
        } else {
          const changes: ISpecificationChanges = {
            specification: specification,
            compareWithSpecificationChanges: null,
            isNew: false,
            isRemoved: false,
            isChanged: false,
          };
          return changes;
        }
      });

      if (applicationChanges.isSupport.compareApplicationChanges) {
        const removedSpecificationsChanges =
          applicationChanges.compareWithApplicationChanges?.specificationsChanges
            .filter((item) => !item.isRemoved)
            .reduce((removedSpecifications, compareSpecification) => {
              const isExistsInCurrentApplication = applicationChanges.specificationsChanges.find(
                (item) => item.specification.id === compareSpecification.specification.id,
              );
              if (!isExistsInCurrentApplication) {
                removedSpecifications.push({
                  specification: compareSpecification.specification,
                  compareWithSpecificationChanges: null,
                  isRemoved: true,
                  isChanged: false,
                  isNew: false,
                });
                applicationChanges.counters.specifications.removed++;
              }
              return removedSpecifications;
            }, [] as ISpecificationChanges[]) || [];

        applicationChanges.specificationsChanges.push(...removedSpecificationsChanges);
      }

      const compareRequirements = (target: IRequirementModel, next: IRequirementModel) => {
        let isChanged = false;
        if (target.shortName !== next.shortName) {
          isChanged = true;
        }
        if (target.description !== next.description) {
          isChanged = true;
        }
        return isChanged;
      };

      applicationChanges.requirementsChanges = applicationRequirements.map((requirement) => {
        if (applicationChanges.isSupport.compareApplicationChanges) {
          const compareRequirementChanges =
            (compareApplicationChanges?.requirementsChanges || []).find(
              (item) => item.requirement.id === requirement.id,
            ) || null;
          const isNew = !compareRequirementChanges;
          const isChanged =
            !isNew &&
            compareRequirementChanges &&
            compareRequirements(requirement, compareRequirementChanges.requirement);
          if (isNew) {
            applicationChanges.counters.requirements.newest++;
          }
          const changes: IRequirementChanges = {
            requirement,
            compareWithRequirementChanges: compareRequirementChanges,
            isChanged: !!isChanged,
            isNew,
            isRemoved: false,
          };
          return changes;
        } else {
          const changes: IRequirementChanges = {
            requirement,
            compareWithRequirementChanges: null,
            isNew: false,
            isRemoved: false,
            isChanged: false,
          };
          return changes;
        }
      });

      if (applicationChanges.isSupport.compareApplicationChanges) {
        const removedRequirementsChanges =
          applicationChanges.compareWithApplicationChanges?.requirementsChanges
            .filter((item) => !item.isRemoved)
            .reduce((removedRequirements, compareRequirement) => {
              const isExistsInCurrentApplication = applicationChanges.requirementsChanges.find(
                (item) => item.requirement.id === compareRequirement.requirement.id,
              );
              if (!isExistsInCurrentApplication) {
                removedRequirements.push({
                  requirement: compareRequirement.requirement,
                  compareWithRequirementChanges: null,
                  isRemoved: true,
                  isChanged: false,
                  isNew: false,
                });
                applicationChanges.counters.requirements.removed++;
              }
              return removedRequirements;
            }, [] as IRequirementChanges[]) || [];

        applicationChanges.requirementsChanges.push(...removedRequirementsChanges);
      }

      applicationChanges.requirementsChanges = applicationChanges.requirementsChanges
        .sort((a, b) => {
          return Number(b.isChanged) - Number(a.isChanged);
        })
        .sort((a, b) => {
          return Number(b.isNew) - Number(a.isNew);
        })
        .sort((a, b) => {
          return Number(b.isRemoved) - Number(a.isRemoved);
        });

      applicationChanges.specificationsChanges = applicationChanges.specificationsChanges
        .sort((a, b) => {
          return Number(b.isChanged) - Number(a.isChanged);
        })
        .sort((a, b) => {
          return Number(b.isNew) - Number(a.isNew);
        })
        .sort((a, b) => {
          return Number(b.isRemoved) - Number(a.isRemoved);
        });

      applicationsChanges.push(applicationChanges);
    }
    applicationsChanges.forEach((applicationChanges) => {
      this.applicationChangesCache.set(
        `${applicationChanges.application?.id}_${applicationChanges.compareWithApplicationChanges?.application?.id}`,
        applicationChanges,
      );
    });
    this.ui.auditApplicationChanges.setList(applicationsChanges.reverse());
  }

  // private async loadApplicationChangesData(application: IApplicationModel, isAudit: boolean = true) {
  //     if (application) {
  //         const auditSettings = isAudit ? {
  //             auditDateInMS: ((application?.auditDate?.getTime() || 0) + (10 * 1000)),
  //             isReplaceId: true
  //         } : {} as any;
  //         const applicationData = await this.rootService.application.data.getById(application.dataId || '', auditSettings);
  //         console.log(applicationData);
  //         const [requirements, specifications] = await Promise.all([
  //             this.rootService.requirement.entity.search({
  //                 limit: 100000,
  //                 filter: {ids: {in: applicationData.savedRequirementsIds}},
  //                 audit: auditSettings
  //             }),
  //             this.rootService.specification.entity.search({
  //                 limit: 100000,
  //                 filter: {ids: {in: application.specificationsIds}},
  //                 audit: auditSettings
  //             }),
  //         ]);
  //
  //         return {
  //             data: applicationData,
  //             requirements: requirements.data,
  //             specifications: specifications.data
  //         }
  //     } else {
  //         return {
  //             data: null,
  //             requirements: [],
  //             specifications: []
  //         }
  //     }
  // }
}
