import { Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { OverflownContent } from '../../LearningUnitPreviewPage/shared/OverflownContent';
import { IUserRatingData } from '../store/IUserRatingUI';
import { LevelBars } from './LevelBars';

export interface IUserRatingTableProperties {
  rows: IUserRatingData[];
  rowAction?: any;
  containerClasses?: string;
  style?: Record<string, string>;
}
export const UserRatingTable = observer(({ rows, rowAction, containerClasses, style }: IUserRatingTableProperties) => {
  const classes = useStyles();

  return (
    <TableContainer style={style} className={containerClasses} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ФИО</TableCell>
            <TableCell>Проекты</TableCell>
            <TableCell>Роль</TableCell>
            <TableCell>Баллы</TableCell>
            <TableCell>Уровень</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:hover .action': { visibility: 'visible' },
                }}
              >
                <TableCell
                  sx={{ color: 'rgba(144, 202, 249, 1)', cursor: 'pointer' }}
                  onClick={() => rowAction?.(row.id)}
                >
                  {row.fullName}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {row.projects.map((project) => (
                      <Chip label={project} title={project} />
                    ))}
                  </Box>
                </TableCell>
                <TableCell>
                <OverflownContent contentClasses={classes.projectRoles}>
                    {row.projectRolesNames.map((item, index, arr) => (
                      <span>
                        {item}
                        {index !== arr.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                  </OverflownContent>
                  </TableCell>
                <TableCell>{row.score}</TableCell>

                <TableCell>
                  <LevelBars level={row.userLevel} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export const useStyles = makeStyles(() => ({
  projectRoles: {
    maxWidth: '300px',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    lineClamp: 2,
    flexWrap: 'wrap',
    textOverflow: 'ellipsis',
  },
}));
