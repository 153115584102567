import { Search } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { SystemsIcon } from './icons/SystemsIcon';
import { GroupCardComponentHeader } from './parts/GroupCardComponent/GroupCardComponentHeader';
import { SystemsList } from './parts/SystemsList';
import { UserUnitCard } from './parts/UserUnitCard';
import { UserUnitListCard } from './parts/UserUnitListCard';
import { IPassingListPageDomain } from './store/IPassingListPageDomain';
import { PassingListPageDomain } from './store/PassingListPageDomain';

export enum FilterValues {
  All = 'all',
  Important = 'important',
  Unimportant = 'unimportant',
}

export interface IPassingListPage {
  layoutDomain: IMainLayoutDomainStore;
}
export const PassingListPage = observer(({ layoutDomain }: IPassingListPage) => {
  const [domain] = useState<IPassingListPageDomain>(new PassingListPageDomain(layoutDomain));
  const [filter, setFilter] = useState<FilterValues>(FilterValues.All);

  useEffect(() => {
    domain.loadData();
  }, [domain]);

  const hasUnits = !!domain.ui.filtredGroupedUnits.list.filter((group) => {
    if (filter === FilterValues.All) {
      return group.importantUnitList.length || group.unimportantUnitList.length;
    } else if (filter === FilterValues.Important) {
      return group.importantUnitList.length;
    } else if (filter === FilterValues.Unimportant) {
      return group.unimportantUnitList.length;
    }
  }).length;

  return (
    <>
      {domain.ui.isLoading.value ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ marginTop: '24px' }}>
          <Accordion elevation={0} sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  position: 'relative',
                  left: '-16px',
                }}
              >
                <SystemsIcon />
                Системы
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <SystemsList systems={domain.ui.applicationsWithUnits.list} />
            </AccordionDetails>
          </Accordion>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '24px',
              alignItems: 'flex-end',
              margin: '24px 0',
              paddingBottom: '24px',
              borderBottom: ' 1px solid rgba(255, 255, 255, 0.1)',
            }}
          >
            <Typography variant="h4" sx={{ marginTop: '32px' }}>
              Карта компетенций
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px', alignItems: 'flex-end' }}>
              <TextField
                size="small"
                onChange={(event) => {
                  domain.handleSearchByGroupsAndName(event.target.value);
                }}
                sx={{ flex: '1' }}
                label="Поиск контекста, юнита"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormControl>
                <InputLabel>Показывать юниты</InputLabel>
                <Select
                  sx={{ width: '160px' }}
                  variant="outlined"
                  size="small"
                  label="Показывать юниты"
                  value={filter}
                  onChange={(event) => {
                    setFilter(event.target.value as FilterValues);
                  }}
                >
                  <MenuItem value={FilterValues.All}>Все</MenuItem>
                  <MenuItem value={FilterValues.Important}>Обязательные</MenuItem>
                  <MenuItem value={FilterValues.Unimportant}>Необязательные</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '48px', marginTop: '48px' }}>
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Box
                sx={{
                  flex: '0 0 28%',
                  gap: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'calc(100vh - 407px)',
                  overflowY: 'scroll',
                }}
              >
                {domain.ui.filtredGroupedUnits.list?.map((group) => {
                  if (
                    (group.importantUnitList.length === 0 && group.unimportantUnitList.length === 0) ||
                    (filter === FilterValues.Important && group.importantUnitList.length === 0) ||
                    (filter === FilterValues.Unimportant && group.unimportantUnitList.length === 0)
                  ) {
                    return null;
                  }

                  return <UserUnitListCard group={group} filter={filter} />;
                })}
              </Box>
              <Box
                sx={{
                  width: '100%',
                  flex: '0 0 70%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  height: 'calc(100vh - 407px)',
                  overflowY: 'scroll',
                }}
              >
                {!hasUnits && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'text.secondary',
                      height: '100%',
                    }}
                  >
                    <Typography variant="h5">Нет юнитов</Typography>
                  </Box>
                )}
                {!!hasUnits &&
                  domain.ui.filtredGroupedUnits.list?.map((group) => {
                    return filter === FilterValues.All ? (
                      <Box sx={{ borderRadius: 4, marginRight: 8 }}>
                        <GroupCardComponentHeader
                          groupName={group.groupName}
                          count={group.importantUnitList.length + group.unimportantUnitList.length}
                          passingPercent={group.passingPercent}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          {!!group.importantUnitList.length &&
                            group.importantUnitList.map((unit) => {
                              const unitResult = domain.ui.passingReslutList.list.find(
                                (unitRes) => unitRes.unitId === unit.id,
                              );
                              return (
                                unitResult && (
                                  <UserUnitCard
                                    unit={unit}
                                    action={domain.redirect}
                                    unitResult={unitResult}
                                    isPassed={unitResult.isPassed}
                                    status="important"
                                  />
                                )
                              );
                            })}
                          {!!group.unimportantUnitList.length &&
                            group.unimportantUnitList.map((unit) => {
                              const unitResult = domain.ui.passingReslutList.list.find(
                                (unitRes) => unitRes.unitId === unit.id,
                              );
                              return (
                                unitResult && (
                                  <UserUnitCard
                                    unit={unit}
                                    action={domain.redirect}
                                    unitResult={unitResult}
                                    isPassed={unitResult.isPassed}
                                    status="unimportant"
                                  />
                                )
                              );
                            })}
                        </Box>
                      </Box>
                    ) : filter === FilterValues.Important ? (
                      !!group.importantUnitList.length && (
                        <Box sx={{ borderRadius: 4, marginRight: 8 }}>
                          <GroupCardComponentHeader
                            count={group.importantUnitList.length}
                            groupName={group.groupName}
                            passingPercent={group.passingPercent}
                          />
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {group.importantUnitList.map((unit) => {
                              const unitResult = domain.ui.passingReslutList.list.find(
                                (unitRes) => unitRes.unitId === unit.id,
                              );
                              return (
                                unitResult && (
                                  <UserUnitCard
                                    unit={unit}
                                    action={domain.redirect}
                                    unitResult={unitResult}
                                    isPassed={unitResult.isPassed}
                                    status="important"
                                  />
                                )
                              );
                            })}
                          </Box>
                        </Box>
                      )
                    ) : (
                      !!group.unimportantUnitList.length && (
                        <Box sx={{ borderRadius: 4, marginRight: 8 }}>
                          <GroupCardComponentHeader
                            count={group.unimportantUnitList?.length}
                            groupName={group.groupName}
                            passingPercent={group.passingPercent}
                          />
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {group.unimportantUnitList.map((unit) => {
                              const unitResult = domain.ui.passingReslutList.list.find(
                                (unitRes) => unitRes.unitId === unit.id,
                              );
                              return (
                                unitResult && (
                                  <UserUnitCard
                                    unit={unit}
                                    action={domain.redirect}
                                    unitResult={unitResult}
                                    isPassed={unitResult.isPassed}
                                    status="unimportant"
                                  />
                                )
                              );
                            })}
                          </Box>
                        </Box>
                      )
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
});
