import React from 'react';

import { observer } from 'mobx-react';
import { MenuItem } from '@mui/material';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ICoreSearchAudit } from '../../../service/coreCommon/common/audit/ICoreSearchAudit';
import { IApplicationModel } from '../../../service/application/entity/IApplicationModel';

export const ExtendedDownloadAcceptanceTests = observer(
  ({
    domain,
    extendedGenerateName,
    format,
    audit,
    popupState,
    listWithAudits = [],
    fullVersion = null,
  }: {
    domain: ApplicationDetailDomain;
    extendedGenerateName: ({
      domain,
      reportName,
    }: {
      domain: ApplicationDetailDomain;
      reportName?: string | undefined;
    }) => string;
    format: string;
    audit: ICoreSearchAudit | undefined;
    popupState: any;
    listWithAudits?: IApplicationModel[] | undefined;
    fullVersion?: string | null;
  }) => {
    // const onlyMajorAudits: IApplicationModel[] | undefined = listWithAudits.filter(
    //   (audit) => audit.versionNumber?.split('.')[0] === majorVersion,
    // );
    let versionNumber = domain.ui.application.entity.versionNumber;

    return (
      <>
        <MenuItem
          onClick={() => {
            domain.downloadApplicationProtocolReport({
              format,
              audit: audit,
              reportName: extendedGenerateName({ domain, reportName: 'Протокол ПСИ ИБ' }),
            });
            popupState.close();
          }}
        >
          Протокол ({format}) {versionNumber}
        </MenuItem>
        {/* TODO try to finish downloading by 3rd version number with statuses*/}
        {/*{onlyMajorAudits.map((application) => (*/}
        {/*  <MenuItem*/}
        {/*    onClick={() => {*/}
        {/*      domain.downloadApplicationProtocolReport({*/}
        {/*        format,*/}
        {/*        audit: {*/}
        {/*          auditDateInMS: application.auditDate ? application.auditDate.getTime() + 10 * 1000 : 0,*/}
        {/*          isReplaceId: true,*/}
        {/*        },*/}
        {/*        reportName: extendedGenerateName({ domain, reportName: 'Протокол ПСИ ИБ' }),*/}
        {/*      });*/}
        {/*      popupState.close();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Протокол ({format}) {application.versionNumber}*/}
        {/*  </MenuItem>*/}
        {/*))}*/}
      </>
    );
  },
);
