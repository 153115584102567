import { IContainer } from '../../common/container/IContainer';
import { SettingsPageTree } from '../../view/admin/page/settings/SettingsPageTree';
import {  isShowApplications,isShowAnalytic, isShowFirst } from '../../view/layout/main/parts/LeftNavigation/LeftNavigationInjects';
import { isAcceptRequirementAvailableExtension } from '../../view/user/page/application/info/inject/isAcceptRequirementAvailableExtension';
import { ApplicationDetailDomain } from '../../view/user/page/application/info/store/ApplicationDetailDomain';
import { ApplicationDetailUI } from '../../view/user/page/application/info/store/ApplicationDetailUI';
import { ProjectInfoUI } from '../../view/user/page/project/info/store/ProjectInfoUI';
import { IsShowAllRequirementVendorList } from '../../view/user/page/project/list/inject/IsShowAllRequirementVendorList';
import { UserProjectTableExtensions } from '../../view/user/page/project/list/parts/extension/UserProjectTableExtensions';
import { ProjectListPageUi } from '../../view/user/page/project/list/store/ProjectListPageUi';
import { IExtension } from '../interface/IExtension';
import { extendApplicationDetailUIConstructor } from './applicationInfo/extendApplicationDetailUIConstructor';
import { extendProjectInfoUIConstructor } from './projectList/extendProjectInfoUIConstructor';
import { extendProjectListPageUiConstructor } from './projectList/extendProjectListPageUiConstructor';
import {ASMLearningPage} from './asmLearningPage/ASMLearningPage'
import { MainLayoutBottomLogoMenu } from '../../view/layout/main/parts/LeftNavigation/parts/MainLayoutBottomLogoMenu';
import {ASMBottomMenuLogo} from './bottomMenuLogo/ASMBottomMenuLogo'
import { MainPageComponent } from '../../view/user/page/main/MainPageInjects';
import { AnalyticsPageComponent } from '../../view/admin/page/analytics/AnalyticsPageInjects';
import {ASMAnalyticsPage} from './analytics/ASMAnalyticsPage'

export default class ASMExtension implements IExtension {
  async loadExtension(container: IContainer) {

    container = this.setSettingsPage(container);

    container = this.setMainPage(container);

    container.set(MainLayoutBottomLogoMenu,ASMBottomMenuLogo );

    const settingsTree = container.get(SettingsPageTree);
    const asmSettingsTree = {};
    const allowedIds: string[] = ['user', 'specification', 'integration', 'applicationTemplate', 'performer'];
    for (const treeIndex in settingsTree) {
      const treeItem = settingsTree[treeIndex];
      if (allowedIds.includes(treeItem.id)) {
        asmSettingsTree[treeIndex] = treeItem;
      }
      if (treeIndex === 'integration' && asmSettingsTree[treeIndex]?.settingsItem?.buttons) {
        asmSettingsTree[treeIndex].settingsItem.buttons = asmSettingsTree[treeIndex]?.settingsItem?.buttons?.filter(
          (item) => item.title !== 'Jira',
        );
      }
    }
    
    container.setByToken(SettingsPageTree, asmSettingsTree);
    container.setByToken(IsShowAllRequirementVendorList, false);

    const projectListPageUiConstructor = container.get(ProjectListPageUi);
    const extendedProjectListPageUiConstructor = extendProjectListPageUiConstructor(projectListPageUiConstructor);
    container.set(ProjectListPageUi, extendedProjectListPageUiConstructor);

    const projectInfoUIConstructor = container.get(ProjectInfoUI);
    const extendedProjectInfoUIConstructor = extendProjectInfoUIConstructor(projectInfoUIConstructor);
    container.set(ProjectInfoUI, extendedProjectInfoUIConstructor);

    const applicationDetailUIConstructor = container.get(ApplicationDetailUI);
    const extendedApplicationDetailUIConstructor = extendApplicationDetailUIConstructor(applicationDetailUIConstructor);
    container.set(ApplicationDetailUI, extendedApplicationDetailUIConstructor);

    let verifyRequirementsHandlers = container.get(isAcceptRequirementAvailableExtension);
    verifyRequirementsHandlers.push((domain: ApplicationDetailDomain) => {
      return false;
    });

    const userProjectListPageTableExtensions = container.get(UserProjectTableExtensions);
    if(userProjectListPageTableExtensions.length > 0){
      userProjectListPageTableExtensions.pop()
    }
    container = this.setAnalyticsPageMain(container);

  }

  setSettingsPage(container: IContainer): IContainer {
    container.set(isShowFirst,()=>true)
    container.set(isShowApplications,()=>false)
    container.set(isShowAnalytic,()=>false)
    return container;
  }

  setMainPage(container: IContainer,): IContainer {
    container.set(MainPageComponent,{
      Component: ASMLearningPage
    })
    return container;
  }
  setAnalyticsPageMain(container: IContainer,): IContainer {
    container.set(AnalyticsPageComponent,{
      Component: ASMAnalyticsPage
    })
    return container;
  }
}
