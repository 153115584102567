import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IApplicationsWithUnits } from '../store/IGroupedUnit';

export interface ISystemsList {
  systems: IApplicationsWithUnits[];
}

export const SystemsList = observer(({ systems }: ISystemsList) => {
  const Dash = ({ isPassed }: { isPassed: boolean | null }) => (
    <Box
      sx={{
        width: '100%',
        height: '4px',
        backgroundColor: isPassed ? '#76c893' : 'rgba(255, 255, 255, 0.12)',
        marginRight: '2px',
        '&:last-child': {
          marginRight: 0,
        },
      }}
    />
  );

  if (systems.length === 0) {
    return <Typography variant="body1">Нет доступных систем.</Typography>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '32px',
      }}
    >
      {systems.map((system) => {
        const passedCount = system.unitResults.filter((result) => result.isPassed).length;

        if (system.units.length === 0 || system.unitResults.length === 0) {
          return null;
        }
        return (
          <Box
            sx={{
              flexShrink: 0,
              width: '368px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              borderRadius: '16px',
              border: '1px solid',
              borderColor: 'rgba(255, 255, 255, 0.12)',
              padding: '16px',
            }}
            key={system.id}
          >
            <Typography variant="h6" sx={{ marginBottom: '8px' }}>
              {system.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: '2px', width: '100%' }}>
              {system.units.map((_, index) => (
                <Dash key={index} isPassed={index < passedCount} />
              ))}
            </Box>
            <Typography variant="body2">
              Прошел {system.unitResults.filter((result) => result.isPassed).length}/{system.units.length}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
});
